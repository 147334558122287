import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddClinicalComponent } from './add-clinical/add-clinical.component';
import { AddDisclaimerComponent } from './add-disclaimer/add-disclaimer.component';
import { AddDrugComponent } from './add-drug/add-drug.component';
import { AddFilterComponent } from './add-filter/add-filter.component';
import { AddGenePatternComponent } from './add-gene-pattern/add-gene-pattern.component';
import { AddHotspotTrailComponent } from './add-hotspot-trail/add-hotspot-trail.component';
import { AddInterpretationComponent } from './add-interpretation/add-interpretation.component';
import { AddPathwayComponent } from './add-pathway/add-pathway.component';
import { CancerTypeComponent } from './cancer-type/cancer-type.component';
import { ClinicalTrailComponent } from './clinical-trail/clinical-trail.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { DrugsComponent } from './drugs/drugs.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { GenePathwayComponent } from './gene-pathway/gene-pathway.component';
import { GenePatternComponent } from './gene-pattern/gene-pattern.component';
import { GeneComponent } from './gene/gene.component';
import { HotspotTrailsComponent } from './hotspot-trails/hotspot-trails.component';
import { InterpretationsComponent } from './interpretations/interpretations.component';
import { PathwayComponent } from './pathway/pathway.component';
import { SuperAdminComponent } from './super-admin.component';
import { TherapyComponent } from './therapy/therapy.component';
import { CancerSiteComponent } from './cancer-site/cancer-site.component';
import { CancerSitegeneComponent } from './cancer-sitegene/cancer-sitegene.component';
import { AddCancersitegeneComponent } from './add-cancersitegene/add-cancersitegene.component';
import { AddPrognasticBiomarkerComponent } from './add-prognastic-biomarker/add-prognastic-biomarker.component';
import { PrognasticBiomarkerComponent } from './prognastic-biomarker/prognastic-biomarker.component';
import { TestTypeGeneComponent } from './test-type-gene/test-type-gene.component';
import { AddTestTypeGeneComponent } from './add-test-type-gene/add-test-type-gene.component';
import { DomainFunctionComponent } from './domain-function/domain-function.component';
import { TruncationDetailsComponent } from './truncation-details/truncation-details.component';
import { RecurrenceVariantComponent } from './recurrence-variant/recurrence-variant.component';
import { AddRecurrenceVariantComponent } from './add-recurrence-variant/add-recurrence-variant.component';
import { PubMedServerComponent } from './pub-med-server/pub-med-server.component';
import { Pdl1TrailsComponent } from './pdl1-trails/pdl1-trails.component';
import { AddPdl1TrailComponent } from './add-pdl1-trail/add-pdl1-trail.component';

const routes: Routes = [
  {
    path: '',
    component: SuperAdminComponent,
    children: [
      {
        path: '',
        component: CancerTypeComponent
      },
      {
        path: 'cancer_types',
        component: CancerTypeComponent
      },
      {
        path: 'cancer_site',
        component: CancerSiteComponent
      },
      {
        path: 'cancer_site_gene',
        component: CancerSitegeneComponent
      },
      {
        path: 'add-cancer_site_gene',
        component: AddCancersitegeneComponent
      },
      {
        path:'edit-cancer_site_gene/:id',
        component: AddCancersitegeneComponent
      },
      {
        path: 'genes',
        component: GeneComponent
      },
      {
        path: 'domain_funtions',
        component: DomainFunctionComponent
      },
      {
        path: 'pub_med_server',
        component: PubMedServerComponent
      },
      {
        path: 'truncation_details',
        component: TruncationDetailsComponent
      },
      {
        path: 'pathways',
        component: PathwayComponent
      },
      {
        path: 'add-pathway',
        component: AddPathwayComponent
      },
      {
        path: 'add-pathway/:id',
        component: AddPathwayComponent
      },
      {
        path: 'gene-pathways',
        component: GenePathwayComponent
      },
      {
        path: 'add-drug',
        component: AddDrugComponent
      },
      {
        path: 'add-drug/:id',
        component: AddDrugComponent
      },
      {
        path: 'drugs',
        component: DrugsComponent
      },
      {
        path: 'therapys',
        component: TherapyComponent
      },
      {
        path: 'add-clinical-trail',
        component: AddClinicalComponent
      },
      {
        path: 'add-clinical-trail/:id',
        component: AddClinicalComponent
      },
      {
        path: 'clinical-trails',
        component: ClinicalTrailComponent
      },
      {
        path: 'add-hotspot-clinical-trail',
        component: AddHotspotTrailComponent
      },
      {
        path: 'add-hotspot-clinical-trail/:id',
        component: AddHotspotTrailComponent
      },
      {
        path: 'hotspot-clinical-trails',
        component: HotspotTrailsComponent
      },
      {
        path: 'add-interpretation',
        component: AddInterpretationComponent
      },
      {
        path: 'add-interpretation/:id',
        component: AddInterpretationComponent
      },
      {
        path: 'interpretations',
        component: InterpretationsComponent
      },
      {
        path: 'disclaimer',
        component: DisclaimerComponent
      },
      {
        path: 'add-disclaimer',
        component: AddDisclaimerComponent,
      },
      {
        path: 'add-disclaimer/:id',
        component: AddDisclaimerComponent
      },
      {
        path: 'genepattern',
        component: GenePatternComponent
      },
      {
        path: 'add-genepattern',
        component: AddGenePatternComponent,
      },
      {
        path: 'add-genepattern/:id',
        component: AddGenePatternComponent
      },
      {
        path: 'testtypegene',
        component: TestTypeGeneComponent
      },
      {
        path: 'add-testtypegene',
        component: AddTestTypeGeneComponent,
      },
      {
        path: 'add-testtypegene/:id',
        component: AddTestTypeGeneComponent
      },

      {
        path: 'recurrenceVarient',
        component: RecurrenceVariantComponent
      },
      {
        path: 'add-recurrenceVarient',
        component: AddRecurrenceVariantComponent,
      },
      {
        path: 'add-recurrenceVarient/:id',
        component: AddRecurrenceVariantComponent,
      },
      {
        path: 'add-filter/:id',
        component: AddFilterComponent,
      },
      {
        path: 'add-filter',
        component: AddFilterComponent,
      },
      {
        path: 'filter-engine',
        component: FilterListComponent,
      },
      {
        path:'add-prognasticbiomarker',
        component:AddPrognasticBiomarkerComponent,
      },
      {
        path:'add-prognasticbiomarker/:id',
        component:AddPrognasticBiomarkerComponent,
      },
      {
        path:'prognasticbiomarker',
        component:PrognasticBiomarkerComponent,
      },
      {
        path:'pdl1_drug',
        component:Pdl1TrailsComponent,
      },
      {
        path: 'add_pdl1_drug',
        component : AddPdl1TrailComponent,
      },
      {
        path: 'add_pdl1_drug/:id',
        component: AddPdl1TrailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule { }
