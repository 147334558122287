import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PDL1DrugRes } from 'src/app/_interfaces.ts/admin';
import { PaginationReq } from 'src/app/_interfaces.ts/pagination';
import { PdlDrugService } from 'src/app/_services/pdl-drug.service';

@Component({
  selector: 'app-pdl1-trails',
  templateUrl: './pdl1-trails.component.html',
  styleUrls: ['./pdl1-trails.component.css']
})
export class Pdl1TrailsComponent implements OnInit {

  searchText!:any;
  pdlDrugListSpinner:boolean=true;
  pdlDrugResList!: PDL1DrugRes[];
  pdl1DrugId!: number;
  statusMsg!: string;
  private searchSubject: Subject<any> = new Subject();

  
  constructor(
    private pdlDrugService: PdlDrugService,
        private toastr: ToastrService,
        private router: Router
  ) { 
    this.searchSubject
    .pipe(
      debounceTime(500), // 500ms delay
      distinctUntilChanged() // Only emit if value changes
    )
    .subscribe((data: any) => {
      this.searchText = data.target.value;
      this.filterPdlList({ target: { value: this.searchText } });
    });

  }

  ngOnInit(): void {
    this.getPdl1TrailList();
  }

  getPdl1TrailList(){
    this.pdlDrugService.pdl1TrailList(this.searchText).subscribe(
      (response: any) => {
        this.pdlDrugListSpinner=false
        this.pdlDrugResList = response['payLoad'];
        console.log(this.pdlDrugResList);
      }
    );

  }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  assignPDL1Trail(pdl1Drug: any){
    console.log(pdl1Drug);
    this.pdl1DrugId = pdl1Drug['id'];
  }

  deletePDLTrail(){
    console.log(this.pdl1DrugId);
    this.pdlDrugService.deletePDLDrug(this.pdl1DrugId).subscribe(
      (response: any) =>{
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
    });
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/pdl1_drug']);
    });
  }

  
  getSanitizedContent(content: string): string {
    return content ? content.replace(/<\/?p>/g, '') : '';
  }
  

  onSearchInput(event: any): void {
    this.searchSubject.next(event);
  }

  filterPdlList(data: any) {
    this.pdlDrugListSpinner = true;
    this.searchText = data.target.value;
    this.getPdl1TrailList(); // Call the API here
  }
}
