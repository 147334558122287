<div class="row py-3 px-3 mx-0 cust_shadow top_bar">
  <div class="col">
    <a href="" class="text-decoration-none"
      ><h4 class="mb-0 font-weight-bold pt-1 text_color">Work bench</h4></a
    >
  </div>
  <div class="col">
    <div class="head-name text-right">
      <!-- <div class="add-new">
             <a href="/general_info" class="btn btn-outline-light">Add Patient</a>
         </div> -->
      <div class="dropdown">
        <button
          class="btn position-relative font-weight-bold"
          type="button"
          data-toggle="dropdown"
        >
          {{ appUser.fullName }}
          <i class="zmdi zmdi-account-circle admin-icon ml-1"></i>
          <!-- <i class="zmdi zmdi-caret-down ml-2"></i> -->
          <!-- <span class="caret"></span> -->
        </button>
        <ul class="dropdown-menu cust-drop text-decoration-none px-4">
          <li><a href="/reports">Reports</a></li>
          <!-- <li><a href="#">New Reports</a></li> -->
          <li><a [routerLink]="['/super_admin']">Data Management</a></li>
          <li>
            <a (click)="logout()"
              ><i class="zmdi zmdi-power mr-1 font-weight-bold"></i> Logout</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid px-0">
  <div class="row mx-0">
    <div class="col-2 px-0">
      <div class="navigation-sidebar p-4 bg-secondary sidenav_fixed" style="max-height: 200vh; overflow-y: auto;">
        <ul class="list-unstyled">
          <li><a routerLink="/super_admin/cancer_site">Cancer Site</a></li>
          <li><a routerLink="/super_admin/cancer_site_gene">Cancer Site Gene</a></li>
          <li><a routerLink="/super_admin/cancer_types">Cancer Type</a></li>
          <li>
            <a routerLink="/super_admin/prognasticbiomarker">Prognostic Biomarker</a>
          </li>
          <li>
            <a routerLink="/super_admin/clinical-trails"
              >Inhouse FDA Approved</a
            >
          </li>
          <li>
            <a routerLink="/super_admin/hotspot-clinical-trails"
              >Hotspot Drugs</a
            >
          </li>
          <li><a routerLink="/super_admin/drugs">Drugs</a></li>
          <li><a routerLink="/super_admin/genes">Gene</a></li>
          <li><a routerLink="/super_admin/domain_funtions">Domain Functions</a></li>
          <li><a routerLink="/super_admin/filter-engine">Filter Engine</a></li>
          <!-- <li><a href="/super_admin/gene-pathways">Gene Pathway</a></li> -->
          <li><a routerLink="/super_admin/pathways">Pathway</a></li>
          <li>
            <a routerLink="/super_admin/interpretations">Interpretation</a>
          </li>
          <li><a routerLink="/super_admin/disclaimer">Disclaimer</a></li>
          <li><a routerLink="/super_admin/genepattern">Gene Pattern</a></li>
          <li><a routerLink="/super_admin/recurrenceVarient">Recurrence Variant</a></li>
          <li><a routerLink="/super_admin/testtypegene">Test Type Genes</a></li>
          <li><a routerLink="/super_admin/truncation_details">Truncation Details</a></li>
          <li><a routerLink="/super_admin/pub_med_server">Pub Med Server</a></li>
          <li><a routerLink="/super_admin/pdl1_drug">PDL1 Trails</a></li>
          <li><a routerLink=""></a></li>
          <li><a routerLink=""></a></li>


        </ul>
      </div>
    </div>
    <div class="col pl-0">
      <div class="container-fluid px-0 mb-4 mt-5 pt-4">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
