import { Component, OnInit } from '@angular/core';
import { CancerType, Drug, HotspotClinicalTrailReqDto, PDL1DrugReqDto } from 'src/app/_interfaces.ts/admin';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PdlDrugService } from 'src/app/_services/pdl-drug.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-add-pdl1-trail',
  templateUrl: './add-pdl1-trail.component.html',
  styleUrls: ['./add-pdl1-trail.component.css']
})
export class AddPdl1TrailComponent implements OnInit {

  drugsDropdownSettings = {};
  cancerTypeDropdownSettings = {};
  cancerTypeList!: CancerType[];
  selectedcancertype: CancerType[] = [];
  drugs = [];
  selectedDrugs: Drug[] = [];
  add_pdl1_drug = {} as PDL1DrugReqDto;
  editor = ClassicEditor;
  submitSpinner = false;
  drugnames:string[]=[];
  statusMsg!: string;
  pdlDrugId!: number;
  getSelectedCancerType: CancerType[] = [];
  getSelectedDrug: Drug[] = [];

  constructor(
        private dataAdminService: DataAdminService,
        private pdl1DrugService: PdlDrugService,
        private toastr: ToastrService,
        private router: Router,
        private routerAct: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.add_pdl1_drug.drugSummary = "";
    this.add_pdl1_drug.referenceSummary = "";

    this.getCancerTypes();
    this.getDrugs();
    this.pdlDrugId = +this.routerAct.snapshot.params['id'];
    if(this.pdlDrugId){
      this.getPdlDrug();
    }


    this.drugsDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onSubmit(){
    this.submitSpinner = true;

    if(this.selectedcancertype.length == 1){
      this.add_pdl1_drug.cancerTypeId = this.selectedcancertype[0]['id'];
    }
    if(this.selectedDrugs.length == 1){
      this.add_pdl1_drug.drugId = this.selectedDrugs[0]['id'];
    }
     

    console.log("add_pdl1_drug",this.add_pdl1_drug);
    this.pdl1DrugService.addPDL1Drug(this.add_pdl1_drug).subscribe(
      (response: any) => {
        this.submitSpinner = false;
        console.log("sumbit response",response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/pdl1_drug']);
    });
  }

  getCancerTypes(){
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerTypeList = response['payLoad'];
      }
    );
  }

  getDrugs() {
    this.dataAdminService.getDrugs().subscribe(
      (response: any) => {
        this.drugs = response['payLoad'];
      }
    );
  }

  getPdlDrug(){
    this.pdl1DrugService.getPdlDrug(this.pdlDrugId).subscribe(
      (response:any) => {
        this.add_pdl1_drug = response['payLoad'];
        if(response['payLoad']['drug'] != null){
          this.getSelectedDrug.push(response['payLoad']['drug']);
          this.selectedDrugs = this.getSelectedDrug;
          }

        if(response['payLoad']['cancerType'] != null){
          this.getSelectedCancerType.push(response['payLoad']['cancerType']);
          this.selectedcancertype = this.getSelectedCancerType;
          }
      }
    );
  }

}
