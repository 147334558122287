import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gene } from 'src/app/_interfaces.ts/admin';
import { CoverageFile } from 'src/app/_interfaces.ts/excel';
import { GermlineReportPdfRes, ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';
import html2canvas from "html2canvas";
import { SignaturesDto } from '../pdl1-dashboard/pdl1-dashboard.component';
import { Pdl1ReportService } from './../../../_services/pdl1-report.service';

@Component({
  selector: 'app-pdl1-report-pdf',
  templateUrl: './pdl1-report-pdf.component.html',
  styleUrls: ['./pdl1-report-pdf.component.css']
})
export class Pdl1ReportPdfComponent implements OnInit {

  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title,
    private Pdl1ReportService: Pdl1ReportService,
  ) { }
  reportId!: number;
  report = {} as ReportRes;
  downloadSpinner = false;
  data: any;
  isReportDownload: boolean = false
  todayDate = Date();
  date = Date();
  sampleSource!: string;
  referringClinician!:string;
  labHospital!:string;
  is4basecare=true;
  footerDiscription = "All the 4baseCare test samples are processed at 4baseCare Offshore Development Centre (ODC) - " +
    "Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15109:2012 " +
    "for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO 69P,71/4P,70/BAP,134P, " +
    "76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, " +
    "INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification."
  footerDiscription2 = "4baseCare (Genomics Tarang ODC), SJP2-S1-1F-C wing, Wipro Limited, SEZ, Sarjapur 2, SyNo.69 (P), Doddakannelli, " +
    "Sarjapura Road, Bengaluru 560035, KA, India"


  ngOnInit(): void {
    this.titleService.setTitle("view report");
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
    this.addSignatiureList = {
      addSignature: [],
    };
    this.addSignatiureList.addSignature.push({
      id: 0,
      name: 'Vidya H Veldore',
      qualification: 'PhD',
      designation: 'Vice President - Clinical Operations',
      isDropDownVisible: false
    });
    this.getAllSignatures();

  }
  getReport() {
    this.reportService.getReport(this.reportId).subscribe(
      (response: any) => {
        this.report = response['payLoad'];
        console.log("report--->", this.report);
        if (this.report.labDetails != null) {
        this.sampleSource = this.report.labDetails?.sampleSource?.replace(/FFPE BlockId:/g, '');
        }
        if(this.report.patient.organisationName.includes("4baseCare") || this.report.patient.organisationName.includes("4basecare")|| this.report.patient.organisationName.includes("Lifecell") || this.report.patient.organisationName.includes("Kokilaben")){
          this.is4basecare = true;
        }
        this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
        if(this.is4basecare==true && this.report?.labDetails?.hospital.includes("Cytecare")){
          this.referringClinician="Cytecare Hospitals";
          this.labHospital="Cytecare Hospitals"
        }else{
          this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
          this.labHospital=this.report?.labDetails?.hospital;
        }
      }
    );
  }
  downloadReport() {
    this.downloadSpinner = true;
    this.print()
  }
  page1() {
    this.data = document.getElementById("familyHistoryImg");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      this.print()
    });
  }
  print() {
    this.isReportDownload = true
    setTimeout(() => {
      this.downloadSpinner = false;
      document.title = "tarGT_PD-L1" + "-" + this.report?.patient?.name?.replace(/\./g, ' ')
      window.print()
      this.isReportDownload = false
    }, 3000)
  }
  signReqDtoList: SignaturesDto[] = [];
  addSignatiureList!: {
    addSignature: SignaturesDto[];
  };
  getAllSignatures() {

  // }
  this.signReqDtoList = this.addSignatiureList.addSignature;
    this.Pdl1ReportService
      .getAllSignatures(this.reportId)
      .subscribe((response: any) => {
        console.log('getAllSiganturesByReportId', response['payLoad']);
        if (response['payLoad'].length > 0) {
          response['payLoad'].forEach((element:SignaturesDto) => {
            return this.addSignatiureList.addSignature.push(element);

          });
          this.signReqDtoList = this.addSignatiureList.addSignature;
          console.log("this.signReqDtoList->",this.signReqDtoList)
        }
      });
  }
}
