import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import {
  AddCommentsDto,
  CancerType,
  PDL1DrugDto,
  PDL1IHCDTO,
} from 'src/app/_interfaces.ts/admin';
import { AppUser } from 'src/app/_interfaces.ts/app_user';
import {
  DnaAndRnaDetailsReq,
  ImmunoDetails,
  ReportRes,
  SampleIdData,
} from 'src/app/_interfaces.ts/reports';
import { ClinicalTrailService } from 'src/app/_services/clinical-trail.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { FamilyHistoryService } from 'src/app/_services/family-history.service';
import { ReportService } from 'src/app/_services/report.service';
import { Pdl1FileUploadService } from './../../../_services/pdl1-file-upload.service';
import { Pdl1ReportService } from './../../../_services/pdl1-report.service';
import { pid } from 'process';
import { PdlDrugService } from 'src/app/_services/pdl-drug.service';
export interface SignaturesDto {
  id: number | null;
  name: string;
  qualification: string;
  designation: string;
  isDropDownVisible: Boolean;
}
@Component({
  selector: 'app-pdl1-dashboard',
  templateUrl: './pdl1-dashboard.component.html',
  styleUrls: ['./pdl1-dashboard.component.css'],
})
export class Pdl1DashboardComponent implements OnInit {
  sup = 'A{b} → Superscript';
  sub = 'A~b! → Subscript';
  editor = ClassicEditor;
  loading = false;
  addSignatiureList!: {
    addSignature: SignaturesDto[];
  };
  organisationName!: string;
  reportPDL1Status: any;
  reportSubType: any;
  appUser = {} as AppUser;
  cancerSiteDropdownSettings: any;
  selectedCancerSite: any;
  reportId!: number;
  evidenceSummary!: string;
  reportTypeChange = false;
  clinicalSummary: string = '';
  clinicalHistory: string = '';
  interestGenes = '';
  addNote: string = '';
  comments: string = '';
  specimen: string = '';
  macroscopicFindings: string = '';
  microscopicDescription: string = '';
  impression: string = '';
  editorText: string = '';
  pmIds!: string;
  report = {} as ReportRes;
  isSubmitedSummary = false;
  isSubmitedClinicalSummary = false;
  isSubmitedAdditionalNotes = false;
  isSubmitedIhc = false;
  isSubmitedDnaRna = false;
  immunoDetails = {} as ImmunoDetails;
  sampleIdData = {} as SampleIdData;
  isSubmitedImmunoDetails = false;
  interpretation!: string;
  isSubmitedReferenceWebsite = false;
  referenceWebsite!: string;
  isSubmitedInterpretation = false;
  isSubmitedDisclaimer = false;
  cancerSiteList: any = [];
  reportTypeList: any = [];
  imageUrl!: string;
  imageUrls: string[] = [];
  statusMsg!: string;
  pdl1TrailsList:PDL1DrugDto[]=[];
  isSelectedTrail!: boolean;
  cancerTypeDropdownSettings = {};
  cancerTypeList!: CancerType[];
  selectedcancertype: CancerType[] = [];
  pdl1Ihc = {} as PDL1IHCDTO;
  addCommentsDto = {} as AddCommentsDto;

  patientSamples = [
    { id: 1, name: '-B' },
    { id: 2, name: '-F' },
    { id: 3, name: '-Z' },
  ];
  selected = [];

  intertext1: string = '';
  intertext2: string = '';
  constructor(
    private reportService: ReportService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private trailService: ClinicalTrailService,
    private titleService: Title,
    private Pdl1ReportService: Pdl1ReportService,
    private pdl1FileUploadService: Pdl1FileUploadService,
    private pdlDrugService: PdlDrugService,

  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }

  showError() {
    this.toastr.error(this.statusMsg, '', { timeOut: 3000 });
  }

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    this.organisationName = localStorage.getItem('organisationName') || '';

    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
    this.getReportTypeList();
    this.getCancerTypes();
    this.getAllSignatures();

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.cancerSiteDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.addSignatiureList = {
      addSignature: [],
    };

  }

  getCancerSiteList() {
    this.dataAdminService.getCancerSiteList().subscribe((res: any) => {
      this.cancerSiteList = res['payLoad'];
    });
  }
  getReportTypeList() {
    this.dataAdminService.getAllReportTypes().subscribe((res: any) => {
      this.reportTypeList = res['payLoad'];
      console.log('Report Type List=>', res['payLoad']);
    });
  }


  changeReportStatus(event: any) {
    if (event.value == 'Yes') {
      this.reportService
        .changeReportStatus(this.reportId, 'REPORTED')
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          this.router.navigate(['/reports']);
        });
    }
  }

  changeReportAndOncoStatus(event: any) {
    if (event.value == 'Yes') {
      this.reportService
        .changeReportStatus(this.reportId, 'REPORTED')
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          this.router.navigate([
            '/first72_view_report/' + this.reportId + '/temp1',
          ]);
        });
      this.reportService
        .updateOncoBenchStatus(this.report.patient.ecrfId)
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          // this.router.navigate(['/first72_view_report/'+this.reportId+'/no_temp']);
        });
    }
  }


  addClinicalSummary() {
    this.isSubmitedClinicalSummary = true;
    if (
      this.clinicalSummary == null ||
      this.clinicalSummary == undefined ||
      this.clinicalSummary == ''
    ) {
      this.clinicalSummary = ' ';
    }
    this.reportService
      .addClinicalSummary(this.reportId, this.clinicalSummary)
      .subscribe((response: any) => {
        console.log(response);
        this.isSubmitedClinicalSummary = false;
        this.statusMsg = response['message'];
        this.showSuccess();
      });

  }



  addIHCDetails() {
    this.isSubmitedIhc = true;
    this.pdl1Ihc.clinicalHistory = this.clinicalHistory;
    this.pdl1Ihc.specimen = this.specimen;
    this.pdl1Ihc.macroscopicFindings = this.macroscopicFindings;
    this.pdl1Ihc.microscopicDescription = this.microscopicDescription;
    this.pdl1Ihc.impression = this.impression;
    console.log("---->", this.pdl1Ihc);

    this.Pdl1ReportService
      .addPdl1IhcDetails(this.report.id, this.pdl1Ihc)
      .subscribe((response: any) => {
        this.isSubmitedIhc = false;
        this.statusMsg = response['message'];
        this.showSuccess();
        console.log(response);
      });
  }
  cancerSiteDeSelect(cancerSite: any) {
    this.reportService
      .addFirstCancerSite(this.reportId, 0)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerSiteSelect(cancerSite: any) {
    this.reportService
      .addFirstCancerSite(this.reportId, cancerSite.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerTypeSelect(cancerType: any) {
    console.log('select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, cancerType.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerTypeDeSelect(cancerType: any) {
    console.log('de-select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, 0)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  selectedInterpretations: string[] = [];
  originalPmids: string = '';
  addedPmids: any = '';
  selectedInterpretationPmids: string[] = [];
  selectedReferneceLink: string[] = [];
  addedRefernceLink: string = '';
  originalRefernce: string = '';

  deletedPMID!: string;
  deletedRefernceLink!: string;

  // textcount(classname: any) {
  //   var dom = document.getElementsByClassName(classname);
  //   var data: any = dom[0];
  //   this.editorText = data.outerText;
  // }
  showSummary = false;
  uploadImage(event: any) {
    if (this.imageUrls.length == 3) {
      this.imageUrls = [];
    }
    const files = event.target.files;
    // this.images.push(event.target.files);
    // const files = this.images;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formdata: FormData = new FormData();
      formdata.append('file', file);
      this.dataAdminService.uploadImage(formdata).subscribe((response: any) => {
        this.imageUrl = response['payLoad'];
        this.imageUrls.push(this.imageUrl);
        console.log('imageUrls\n', this.imageUrls);
      });
    }
  }

  imagesPresent!: boolean;


  addSampleID() {
    this.sampleIdData.ecrfId = this.report.patient.ecrfId;
    this.sampleIdData.samples = this.selected;
    this.sampleIdData.testPackage = this.report.testPackage;
    this.sampleIdData.sampleCollected = this.report.labDetails.sampleCollected;
    console.log(this.sampleIdData);

    this.reportService
      .addSampleID(this.sampleIdData)
      .subscribe((response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.getReport();
      });
  }

  // pdl1Trails() {
  //   if (
  //     this.immunoDetails.pdl1Status == 'EXPRESSION' ||
  //     this.report.reportPDL1Status == 'Positive'
  //   ) {
  //     this.dataAdminService
  //       .getAllClinicalTrailByGene('PD-L1')
  //       .subscribe((response: any) => {
  //         this.clinicalTrialResList = response['payLoad'];
  //         console.log('clinical trail db', this.clinicalTrialResList);
  //         this.selectedPDL1Trails();
  //       });
  //   }
  // }

  searchText!:any;
  pdlDrugListSpinner:boolean=true;
  getPdl1TrailList(){
    this.pdlDrugService.pdl1TrailList(this.searchText).subscribe(
      (response: any) => {
        this.pdlDrugListSpinner=false
        this.pdl1TrailsList = response['payLoad'];
        console.log(this.pdl1TrailsList);
        this.getAllSelectedPDL1Trails();

      }
    );

  }
  isAllSelectedOrDeSelected = false;
  selectAllOrDeSelectAllTrails(event: any) {
    this.isAllSelectedOrDeSelected = event['target']['checked'];

    this.pdl1TrailsList.forEach((trial) => {
      this.pdlDrugService
        .addPDL1Trails(this.reportId, trial.id, this.isAllSelectedOrDeSelected)
        .subscribe((response: any) => {
          console.log(response);
        });
    });
    this.getPdl1TrailList();
  }
  selectedPDL1TrailIds: number[] = [];
  getAllSelectedPDL1Trails() {
    this.pdlDrugService
      .getSelectedPDL1Trails(this.reportId)
      .subscribe((response: any) => {
        this.selectedPDL1TrailIds = response['payLoad'];
        console.log('getSelectedPDL1Trails', this.selectedPDL1TrailIds);
        this.pdl1TrailsList.forEach((element) => {
          if (this.selectedPDL1TrailIds.includes(element.id)) {
            console.log("element",element)
            element.selected = true;
          }
        });
      });
  }

  addSelectedTrails(event: any, internalId: number) {
    this.isSelectedTrail = event['target']['checked'];
    this.pdlDrugService
      .addPDL1Trails(this.reportId, internalId, this.isSelectedTrail)
      .subscribe((response: any) => {
        console.log(response);
      });
  }




  addReportSubType(event: any) {
    console.log('selected=>', event.name);
    this.Pdl1ReportService
      .addReportSubType(this.reportId, this.reportSubType)
      .subscribe((response: any) => {
        this.reportTypeChange = false;
        console.log('addReportType response', response);
        if (response['success']) {
          // this.getReport();
          window.location.reload();
        }
      });
  }
  addReportStatus(event: any) {
    console.log('selected=>', event.name);
    this.Pdl1ReportService
      .addReportStatus(this.reportId, this.reportPDL1Status)
      .subscribe((response: any) => {
        this.reportTypeChange = false;
        console.log('addReportType response', response);
        if (response['success']) {
          // this.getReport();
          window.location.reload();
        }
      });
  }


  referringClinician!: string;
  ageOfBlock!: any;
  getReport() {
    this.reportService.getReport(this.reportId).subscribe((response: any) => {
      this.report = response['payLoad'];
      if (this.report.reportPDL1Status == 'Positive') {
        this.getPdl1TrailList();
      }
      console.log('clientType--->', this.report.clientType);
      console.log('report ==>', this.report);
      if (this.organisationName != null && this.organisationName != '') {
        this.getAndSetOncoBenchPatientsDetails();
      }

      if (this.report.patient.organisationName != null) {

        if (this.report.reportType != null) {
          this.titleService.setTitle(
            this.report.patient.ecrfId + ' - ' + this.report.reportType.name
          );

          if (this.report.reportType.name == 'PDL1') {
            this.reportPDL1Status = this.report.reportPDL1Status;
            this.reportSubType = this.report.reportPDL1SubType;
            this.specimen = this.report.specimen;
            this.clinicalHistory = this.report.clinicalHistory;
            this.addNote = this.report.addNotes;
            this.comments = this.report.comments;
            this.macroscopicFindings = this.report.macroscopicFindings;
            this.microscopicDescription = this.report.microscopicDescription;
            this.impression = this.report.impression;
          }
        }
        if (this.report.firstCancerType != null) {
          this.selectedcancertype.push(this.report.firstCancerType);
        }

        this.referringClinician = this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
        if (this.report.labDetails != null) {
          this.dnaAndRnaDetailsReq.dnaAvailable = this.report.labDetails.dnaAvailable;
          if (this.report.labDetails.dnaAvailable) {
            this.dnaAvailableValue = "yes";
          } else if (!this.report.labDetails.dnaAvailable) {
            this.dnaAvailableValue = "no";
          }
          this.dnaAndRnaDetailsReq.dnaSequencingData = this.report.labDetails.dnaSequencingData;
          this.dnaAndRnaDetailsReq.rnaAvailable = this.report.labDetails.rnaAvailable;
          if (this.report.labDetails.rnaAvailable) {
            this.rnaAvailableValue = "yes";
          } else if (!this.report.labDetails.rnaAvailable) {
            this.rnaAvailableValue = "no";
          }
          this.dnaAndRnaDetailsReq.rnaSequencingData = this.report.labDetails.rnaSequencingData;
          if (this.report.labDetails.sampleSource != null || this.report.labDetails.sampleSource != undefined) {
            this.report.labDetails.sampleSource = this.report.labDetails.sampleSource.replace(/FFPE BlockId:/g, '')
          }
        }
      }
    });
  }

  getCancerTypes() {
    this.dataAdminService.cancerTypeList().subscribe((response: any) => {
      this.cancerTypeList = response['payLoad'];
    });
  }
  getAndSetOncoBenchPatientsDetails() {
    this.reportService
      .getAndSetOncoBenchPatientsDetails(this.reportId)
      .subscribe((response: any) => {
        this.report = response['payLoad'];
        console.log('patient==>', this.report);
        this.clinicalSummary = this.report.clinicianIndication;
        this.report.labDetails.sampleId = this.report.labDetails.sampleId;
        this.report.labDetails.sampleSource =
          this.report.labDetails.sampleSource;
      });
  }
  dnaAndRnaDetailsReq = {} as DnaAndRnaDetailsReq;
  dnaAvailableValue!: string;
  rnaAvailableValue!: string;
  addingRnaAndDna() {
    this.isSubmitedDnaRna = true;
    this.reportService.addingRnaAndDna(this.reportId, this.dnaAndRnaDetailsReq).subscribe(
      (response: any) => {
        console.log(response);
        this.isSubmitedDnaRna = false;
        this.statusMsg = response['message'];
        this.showSuccess();
      }
    );
  }
  dnaAvailable(event: any) {
    if (event.value == 'yes') {
      this.dnaAndRnaDetailsReq.dnaAvailable = true;
    } else {
      this.dnaAndRnaDetailsReq.dnaAvailable = false;
    }

    console.log(this.dnaAndRnaDetailsReq);
  }

  rnaAvailable(event: any) {
    if (event.value == 'yes') {
      this.dnaAndRnaDetailsReq.rnaAvailable = true;
    } else {
      this.dnaAndRnaDetailsReq.rnaAvailable = false;
    }
    console.log(this.dnaAndRnaDetailsReq);
  }

  selectedFile?: any;
  onFileChange(event: any) {
    console.log("event", event)
    const files = event.target.files;
    console.log(files)
    this.selectedFile = files[0];
    this.loading = true;
    let filename = files[0].name.split(".")[0];
    filename = filename.trim().replaceAll(" ", "_");
    filename = `${filename}_${(new Date().getTime())}.${files[0].name.split(".").pop()}`
    console.log(" this.selectedFile-->", filename);
    if (this.selectedFile) {
      this.uploadReport();
      // this.getProcessedReport("pdl11.pdf_reply.txt")
    }
  }

  uploadReport() {
    let filename = this.selectedFile.name
    if (this.selectedFile != null) {
      this.pdl1FileUploadService.uploadReport(this.selectedFile, filename).subscribe((res) => {
        console.log(" uploadReport res->", res)

        this.getProcessedReport(res?.report_id)
      },
        (error) => {
          console.error('Error during upload:', error);
          this.loading = false;
        });
    }
  }
  isDropDownList = false

  getProcessedReport(id: any) {
    console.log("id", id);
    if (this.selectedFile != null) {
      this.pdl1FileUploadService.getProcessedReportData(id).subscribe((res) => {
        console.log(" getProcessedReport res->", res)
        console.log(" getProcessedReport res->", res.processed_data)
        console.log("  res.processed_data.parameters.specimen res->", res.processed_data.parameters[0].specimen)

        this.specimen = res.processed_data.parameters[0].specimen;
        this.macroscopicFindings = res.processed_data.parameters[0].macroscopicFindings;
        this.impression = res.processed_data.parameters[0].impression;
        this.microscopicDescription = res.processed_data.parameters[0].microscopicDescription;
        this.clinicalHistory = res.processed_data.parameters[0].clinicalHistory;
        this.comments = res.processed_data.parameters[0].comments;
        this.addNote = res.processed_data.parameters[0].note;
        this.loading = false;
      },
        (error) => {
          console.error('Error retrieving processed report:', error);
          this.loading = false;
        });
    }
  }
  //   sys_prompt="sys_prompt_hcg.txt";
  //   processReport(fileName:any){

  //     if(this.selectedFile!=null){
  //       this.pdl1FileUploadService.processReport(fileName,this.sys_prompt).subscribe((res:any)=>{
  //         console.log(" processReport res->",res)
  //         let response:string=res['status']
  //         if(response.includes("Processing started") ){
  //           this.callGetProcessedReportAfterDelay(fileName);
  //         }else {
  //           this.loading = false;
  //           console.warn('Processing not started:', res);
  //         }
  //       },(error) => {
  //         console.error('Error during processing:', error);
  //         this.loading = false;
  //       });
  //     }
  //   }
  //   private retryInterval: any;
  //   callGetProcessedReportAfterDelay(fileName:any): void {
  //     this.loading = true;
  //     fileName=fileName+"_reply.txt"
  //     this.retryInterval = setInterval(() => {
  //       this.loading = true;
  //       this.getProcessedReport(fileName);
  //     }, 15000);
  //   }

  //   getProcessedReport(fileName:any){
  //     console.log("fileName",fileName);
  //     if(this.selectedFile!=null){
  //       this.pdl1FileUploadService.getProcessedReport(fileName).subscribe((res)=>{
  //         console.log(" getProcessedReport res->",res)
  //         if(res!=null && res.includes("json\n{")){
  //           const jsonString=res.split("json\n")[1]
  //           let jsonData = JSON.parse(jsonString);
  //           console.log(" getProcessedReport res->",jsonData)
  //           this.specimen=jsonData.specimen;
  //           this.macroscopicFindings=jsonData.macroscopicFindings;
  //           this.impression=jsonData.impression;
  //           this.microscopicDescription=jsonData.microscopicDescription;
  //           this.clinicalHistory=jsonData.clinicalHistory;
  //           this.comments=jsonData.comments;
  //           this.addNote=jsonData.note;
  //         }
  //         setTimeout(() => {
  //         }, 1000);
  //         this.loading = false;
  //         clearInterval(this.retryInterval);
  //       },
  //       (error) => {
  //         console.error('Error retrieving processed report:', error);
  //       });
  //     }
  //   }
  // ngOnDestroy(): void {
  //     if (this.retryInterval) {
  //       clearInterval(this.retryInterval); // Clear the interval
  //     }
  //   }
  addCommentsAndNotes() {
    this.isSubmitedInterpretation = true;
    this.addCommentsDto.comments = this.comments;
    this.addCommentsDto.notes = this.addNote;
    this.Pdl1ReportService.addCommentsAndNotes(this.reportId, this.addCommentsDto)
      .subscribe((response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.report.comments = this.comments;
        this.showSuccess();
        this.isSubmitedInterpretation = false;
      });
  }
  signReqDtoList: SignaturesDto[] = [];

  addSignatures(): void {
    if (this.addSignatiureList.addSignature.length >= 3) {
      this.statusMsg = "You cannot add more than 3 signatures!";
      this.showError();
    } else {
      this.addSignatiureList.addSignature.push({
        id: 0,
        name: '',
        qualification: '',
        designation: '',
        isDropDownVisible: false
      });
    }
    this.signReqDtoList = this.addSignatiureList.addSignature;
    console.log(this.signReqDtoList);
  }

  sigantureSaveButton = false;
  addSignatureDetails() {
    this.sigantureSaveButton = true;
    console.log('signReqDtoList', this.signReqDtoList);
    this.Pdl1ReportService
      .addSignatures(this.reportId, this.signReqDtoList)
      .subscribe((response: any) => {
        console.log('addFamilyHistoryDetails response', response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.sigantureSaveButton = false;
      });
  }
  dropdownPrevented = false;

  removeSiganture(i: number, data: any) {
    this.addSignatiureList.addSignature.splice(i, 1);
    this.signReqDtoList = this.addSignatiureList.addSignature;
    this.Pdl1ReportService
      .deleteSignature(data.id)
      .subscribe((res: any) => {
        this.statusMsg = res['message'];
        this.showSuccess();
        this.getAllSignatures();
      });
    console.log(this.signReqDtoList);
  }

  getAllSignatures() {
    this.Pdl1ReportService
      .getAllSignatures(this.reportId)
      .subscribe((response: any) => {
        console.log('getAllSiganturesByReportId', response);
        if (response['payLoad'].length > 0) {
          this.addSignatiureList.addSignature = response['payLoad'];
          this.signReqDtoList = this.addSignatiureList.addSignature;
        }
      });
  }
  searchKey!: string;
  dropDownList: any[] = [];
  newId!: any;

  getDropDownSignatureList(data: any, index: number) {
    this.dropDownList = [];
    this.searchKey = data.target.value;
    if (this.searchKey.length > 3) {
      this.isDropDownList = true
      this.Pdl1ReportService.getDropDownSignatureList(this.searchKey).subscribe((res: any) => {
        this.dropDownList = res["payLoad"];
        this.isDropDownList = false;
        console.log("drop dowm List==>>", this.dropDownList);
      });
    } else {
      this.isDropDownList = false;
      this.dropDownList = [];
    }
  }

  toggleDropDownVisibility(index: number, isVisible: boolean): void {
    if (!this.dropdownPrevented) {
      this.dropDownList = [];
      this.addSignatiureList.addSignature[index].isDropDownVisible = isVisible;
    }
    this.dropdownPrevented = false;
  }

  goToEdit(item: any, add_sign: SignaturesDto, i: number) {
    console.log("id==>", item);
    let pId = item.target.value.split(':')[1];
    this.Pdl1ReportService
      .getSignature(pId)
      .subscribe((res: any) => {
        add_sign.name = res["payLoad"].name;
        add_sign.designation = res["payLoad"].designation;
        add_sign.qualification = res["payLoad"].qualification;
        this.addSignatiureList.addSignature[i].isDropDownVisible = false;
        this.dropdownPrevented = false;
      });
  }
  hideDropDownWithDelay(index: number): void {
    setTimeout(() => {
      if (!this.dropdownPrevented) {
        this.addSignatiureList.addSignature[index].isDropDownVisible = false;
      }
    }, 200);
  }

  preventHideDropDown(): void {
    this.dropdownPrevented = true;
  }
}
