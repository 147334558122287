import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { SuperAdminComponent } from './super-admin.component';
import { CancerTypeComponent } from './cancer-type/cancer-type.component';
import { FormsModule } from '@angular/forms';
import { GeneComponent } from './gene/gene.component';
import { PathwayComponent } from './pathway/pathway.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AddPathwayComponent } from './add-pathway/add-pathway.component';
import { GenePathwayComponent } from './gene-pathway/gene-pathway.component';
import { AddDrugComponent } from './add-drug/add-drug.component';
import { DrugsComponent } from './drugs/drugs.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TherapyComponent } from './therapy/therapy.component';
import { AddClinicalComponent } from './add-clinical/add-clinical.component';
import { ClinicalTrailComponent } from './clinical-trail/clinical-trail.component';
import { TruncatePipe } from '../_services/pipe';
import { InterpretationsComponent } from './interpretations/interpretations.component';
import { AddInterpretationComponent } from './add-interpretation/add-interpretation.component';
import { AddHotspotTrailComponent } from './add-hotspot-trail/add-hotspot-trail.component';
import { HotspotTrailsComponent } from './hotspot-trails/hotspot-trails.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { AddDisclaimerComponent } from './add-disclaimer/add-disclaimer.component';
import { GenePatternComponent } from './gene-pattern/gene-pattern.component';
import { AddGenePatternComponent } from './add-gene-pattern/add-gene-pattern.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddFilterComponent } from './add-filter/add-filter.component';
import { FilterListComponent } from './filter-list/filter-list.component';
// import { AddCancersiteComponent } from './add-cancersite/add-cancersite.component';
import { CancerSiteComponent } from './cancer-site/cancer-site.component';
import { AddCancersitegeneComponent } from './add-cancersitegene/add-cancersitegene.component';
import { CancerSitegeneComponent } from './cancer-sitegene/cancer-sitegene.component';
import { PrognasticBiomarkerComponent } from './prognastic-biomarker/prognastic-biomarker.component';
import { AddPrognasticBiomarkerComponent } from './add-prognastic-biomarker/add-prognastic-biomarker.component';
import { TestTypeGeneComponent } from './test-type-gene/test-type-gene.component';
import { AddTestTypeGeneComponent } from './add-test-type-gene/add-test-type-gene.component';
import { DomainFunctionComponent } from './domain-function/domain-function.component';
import { TruncationDetailsComponent } from './truncation-details/truncation-details.component';
import { RecurrenceVariantComponent } from './recurrence-variant/recurrence-variant.component';
import { AddRecurrenceVariantComponent } from './add-recurrence-variant/add-recurrence-variant.component';
import { PubMedServerComponent } from './pub-med-server/pub-med-server.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Pdl1TrailsComponent } from './pdl1-trails/pdl1-trails.component';
import { AddPdl1TrailComponent } from './add-pdl1-trail/add-pdl1-trail.component';


@NgModule({
  declarations: [
    SuperAdminComponent,
    CancerTypeComponent,
    GeneComponent,
    PathwayComponent,
    AddPathwayComponent,
    GenePathwayComponent,
    AddDrugComponent,
    DrugsComponent,
    TherapyComponent,
    AddClinicalComponent,
    ClinicalTrailComponent,
    TruncatePipe,
    InterpretationsComponent,
    AddInterpretationComponent,
    AddHotspotTrailComponent,
    HotspotTrailsComponent,
    DisclaimerComponent,
    AddDisclaimerComponent,
    GenePatternComponent,
    AddGenePatternComponent,
    AddFilterComponent,
    FilterListComponent,
    // AddCancersiteComponent,
    CancerSiteComponent,
    AddCancersitegeneComponent,
    CancerSitegeneComponent,
    PrognasticBiomarkerComponent,
    AddPrognasticBiomarkerComponent,
    TestTypeGeneComponent,
    AddTestTypeGeneComponent,
    DomainFunctionComponent,
    TruncationDetailsComponent,
    RecurrenceVariantComponent,
    AddRecurrenceVariantComponent,
    PubMedServerComponent,
    Pdl1TrailsComponent,
    AddPdl1TrailComponent,
  ],
  imports: [
    CommonModule,
    Ng2SearchPipeModule,
    SuperAdminRoutingModule,
    FormsModule,
    CKEditorModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,

  ]
})
export class SuperAdminModule { }
