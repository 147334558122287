import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignaturesDto } from '../pages/pdl1Report/pdl1-dashboard/pdl1-dashboard.component';

@Injectable({
  providedIn: 'root'
})
export class Pdl1ReportService {

  constructor(
    private httpClient: HttpClient
  ) { }

  addReportSubType(reportId: number, body: string) {
    return this.httpClient.get('/pdl1/' + reportId + '/add_report_sub_type/' + body);
  }

  addReportStatus(reportId: number, body: string) {
    return this.httpClient.get('/pdl1/' + reportId + '/add_report_status/' + body);
  }

  addPdl1IhcDetails(reportId: number, add_pdl1_ihc: any) {
    return this.httpClient.post('/pdl1/' + reportId + '/add_pdl1_ihc', add_pdl1_ihc)
  }

  addCommentsAndNotes(reportId: number, params: any) {
    return this.httpClient.post('/pdl1/' + reportId + '/add_comments', params);
  }

  addSignatures(reportId: number, signatureDto: SignaturesDto[]){
    return this.httpClient.post('/pdl1/' + reportId +'/add_sign', signatureDto);
  }

  getAllSignatures(reportId: number){
    return this.httpClient.get('/pdl1/' + reportId +'/get_sign_details');
  }

  deleteSignature(id:number){
    return this.httpClient.get('/pdl1/' + id +'/delete_sign');
  }

  getDropDownSignatureList(searchKey: any) {
    return this.httpClient.post("/pdl1/drop_down_sign_list",searchKey);
  }

  getSignature(id:number){
    return this.httpClient.get('/pdl1/' + id +'/get_sign');
  }
}
