<div *ngIf="!report" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
</div>

<div *ngIf="report">
  <button [disabled]="downloadSpinner" type="button" class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()">
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i> Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>

  <!-- Page 1 (title page) -->
  <div id="page" class="container position-relative pagess" style="overflow-y: clip; background-color: white;">
    <div class="row header">
      <div class="w-100 overflow-hidden">
        <div class="col px-0">
          <div class="cover_page">
            <img src="../../../assets/PD-L1 Report Cover.png" alt="" class="img-fluid w-100" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- page 2 -->
  <div id="page2" class="container position-relative page" style="background-color: white">
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient?.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              SAMPLE ID<br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>
            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
          <div class="logo">
            <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
          </div>
        </div>
        <div *ngIf="report.clientName == 'path_labs'" class="col-3">
          <div class="logo">
            <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="mar_top"></div>
    <!--  -->
    <div class="row">
      <div class="col-7">
        <div class="">
          <div class="w-50" style="min-height: 80px">
            <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
              SCOPE OF THE TEST
            </p>
            <p>SNVs, InDels, CNAs, Gene Fusions status</p>
          </div>
          <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
        </div>
      </div>
      <div class="col">
        <div class="">
          <div class="w-100">
            <div class="w-100" style="min-height: 80px">
              <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalHistory != null">
                <span *ngIf="report.clinicalHistory != ' '">CLINICAL History</span>
              </p>

              <p *ngIf="report.clinicalHistory != null">
                <span style="color: #000 !important" *ngIf="report.clinicalHistory != ' '">{{ report.clinicalHistory
                  }}</span>
              </p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="row pt-2">
      <div class="col-7">
        <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-skyblue'">
          Report Details
        </h6>
        <ul class="list-unstyled">
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Name : </span>
            <span class="text-black">{{
              report.patient?.name | uppercase
              }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
            <span class="text-black">{{
              report.patient?.gender | titlecase
              }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
            <span class="text-black">{{
              report.patient?.dateOfBirth
              ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
              : report.patient?.age + " Years"
              }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Reporting Date :
            </span>
            <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
          </li>
        </ul>
      </div>
      <div class="col">
        <ul class="list-unstyled pt-4 mt-3">
          <li *ngIf="report.cancerSubType != null" class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Cancer Celltype :
            </span>
            <span class="text-black">{{ report.cancerSubType }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Sample Source :
            </span>
            <span class="text-black">{{ sampleSource }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Consulting Clinician :
            </span>
            <span class="text-black">{{referringClinician}}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Hospital :
            </span>
            <span class="text-black">{{ labHospital }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Report Sub Type :
            </span>
            <span class="text-black">{{ report.reportPDL1SubType || "NA" }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan" [ngClass]="'txt-skyblue'">Report Status :
            </span>
            <span class="text-black">{{ report.reportPDL1Status || "NA" }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="mar_top"></div>

    <!--  -->
    <!--  -->
    <div class="row mt-2 py-2">
      <div class="col">
        <!-- <h6 class="mb-0 text-uppercase f-weight_700">Results</h6> -->
      </div>
    </div>
    <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
      IHC Details
    </h6>
    <div class="row">

      <div class="col-12" id="firstPageTable">
        <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
          <tr>
            <th>Specimen</th>
            <td>{{report.specimen}}</td>
          </tr>
          <tr>
            <th>Macroscopic findings</th>
            <td>{{report.macroscopicFindings}}</td>
          </tr>
          <tr>
            <th>Microscopic description</th>
            <td>{{report.microscopicDescription}}</td>
          </tr>
          <tr>
            <th>Impression</th>
            <td>{{report.impression}}</td>
          </tr>



        </table>

      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">

        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br />{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- page 3 -->
  <div id="page3" class="container position-relative page" style="background-color: white">
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient?.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              SAMPLE ID<br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>
            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div *ngIf="report.clientType == '4basecare_client'">
        <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
          <div class="logo">
            <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
          </div>
        </div>
        <div *ngIf="report.clientName == 'path_labs'" class="col-3">
          <div class="logo">
            <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="mar_top"></div>
    <!--  -->

    <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
      Comments
    </h6>
    <!--  -->
    <div class="row">
      <div class="col-12">
        <p>{{report.comments}}</p>
      </div>
    </div>
    <br />
    <!--  -->
    <br />
    <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
      Notes
    </h6>
    <!--  -->
    <div class="row">
      <div class="col-12">
        <p>{{report.addNotes}}</p>
      </div>
    </div>
    <!--  -->
    <div class="img_signs above-footer">
      <div class="row d-flex justify-content-between">
        <div class="col-3" *ngFor="let sign of signReqDtoList">

          <p style="
                text-align: center;

                font-size: 15px !important;
              ">(Electronically signed by)
          </p>
          <p style="
                  text-align: center;
                  font-weight: bolder;
                  font-size: 15px !important;
                ">
            {{sign.name}}, {{sign.qualification}}<br />{{sign.designation}}
          </p>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="row footer">
      <div class="col-12">

        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br />{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
