import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationReq } from '../_interfaces.ts/pagination';
import { PDL1DrugReqDto } from '../_interfaces.ts/admin';

@Injectable({
  providedIn: 'root'
})
export class PdlDrugService {

  constructor(
    private httpClient: HttpClient
  ) { }

    pdl1TrailList(searchText : String){
      const url = (searchText == undefined || searchText == null || searchText.trim() === '')
      ? ('/pdl1/drug/get_all')
      : ('/pdl1/drug/get_all/'+searchText)
      return this.httpClient.get(url);
    }

    deletePDLDrug(pdl1DrugId: number) {
      return this.httpClient.delete('/pdl1/drug/delete/'+pdl1DrugId)
    }

    addPDL1Drug(add_pdl1_drug: PDL1DrugReqDto) {
      return this.httpClient.post('/pdl1/drug/add', add_pdl1_drug)
    }

    getPdlDrug(pdlDrugId: number) {
      return this.httpClient.get('/pdl1/drug/get_by_id/'+pdlDrugId)
    }

    addPDL1Trails(reportId: number, trailId: number, isSelected: boolean){
      return this.httpClient.post('/pdl1/drug/' + reportId + '/select_pdl1_trail/' + trailId,isSelected);
    }

    getSelectedPDL1Trails(reportId: number){
      return this.httpClient.get('/pdl1/drug/' + reportId  + '/get_selected_pdl1_trails');
    }
}
