import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import {
  CancerType,
  ClinicalTrialRes,
  DisclaimerVariants,
  InterpretationDTO,
} from 'src/app/_interfaces.ts/admin';
import { AppUser } from 'src/app/_interfaces.ts/app_user';
import { FamilyReqDto } from 'src/app/_interfaces.ts/family';
import {
  DnaAndRnaDetailsReq,
  ImmunoDetails,
  ReportRes,
  SampleIdData,
} from 'src/app/_interfaces.ts/reports';
import { ClinicalTrailService } from 'src/app/_services/clinical-trail.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { FamilyHistoryService } from 'src/app/_services/family-history.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-first72-liquid-dashboard',
  templateUrl: './first72-liquid-dashboard.component.html',
  styleUrls: ['./first72-liquid-dashboard.component.css']
})
export class First72LiquidDashboardComponent implements OnInit {
  sup = 'A{b} → Superscript';
  sub = 'A~b! → Subscript';
  editor = ClassicEditor;

  organisationName!: string;
  newreportType = '';
  appUser = {} as AppUser;
  cancerSiteDropdownSettings: any;
  selectedCancerSite: any;
  reportId!: number;
  evidenceSummary!: string;
  reportTypeChange = false;
  clinicalSummary!: string;
  interestGenes = '';
  addNote!: string;
  editorText: string = '';
  pmIds!: string;
  report = {} as ReportRes;
  isSubmitedSummary = false;
  isSubmitedClinicalSummary = false;
  isSubmitedAdditionalNotes = false;

  isSubmitedDnaRna = false;
  immunoDetails = {} as ImmunoDetails;
  sampleIdData = {} as SampleIdData;
  isSubmitedImmunoDetails = false;
  interpretation!: string;
  isSubmitedReferenceWebsite = false;
  referenceWebsite!: string;
  isSubmitedInterpretation = false;
  isSubmitedDisclaimer = false;
  cancerSiteList: any = [];
  reportTypeList: any = [];
  imageUrl!: string;
  imageUrls: string[] = [];
  statusMsg!: string;
  clinicalTrialResList: ClinicalTrialRes[] = [];
  isSelectedTrail!: boolean;
  isGermlinePlus!: boolean;
  reportType!: string;
  isTargetFirst = false;
  hrrSomaticReport: boolean = false;
  disclaimertxt: any = '';
  cancerTypeDropdownSettings = {};
  cancerTypeList!: CancerType[];
  selectedcancertype: CancerType[] = [];
  InterpretationDTO = {} as InterpretationDTO;
  addFamilyHistory!: {
    addFamilyHistorys: FamilyReqDto[];
  };

  familyReqDtoList: FamilyReqDto[] = [];

  significanceResult!: string;

  patientSamples = [
    { id: 1, name: '-B' },
    { id: 2, name: '-F' },
    { id: 3, name: '-Z' },
  ];
  selected = [];

  interpretationDropdownSettings = {};
  disclaimerDropdownSettings = {};
  disclaimerList: DisclaimerVariants[] = [];
  selectedDisclaimer: any = [];
  disclaimer: any = '';
  disclaimerNote: any;
  keyInformation: any;
  tab: any = '';
  dnaAndRnaDetailsReq = {} as DnaAndRnaDetailsReq;
  dnaAvailableValue!: string;
  rnaAvailableValue!: string;

  constructor(
    private reportService: ReportService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private trailService: ClinicalTrailService,
    private titleService: Title,
    private familyHistoryService: FamilyHistoryService
  ) {}

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }

  showError() {
    this.toastr.error(this.statusMsg, '', { timeOut: 3000 });
  }

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    this.organisationName = localStorage.getItem('organisationName') || '';

    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
    this.getReportTypeList();
    this.getCancerTypes();

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.cancerSiteDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.disclaimerDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'disclaimerTag',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  getCancerSiteList() {
    this.dataAdminService.getCancerSiteList().subscribe((res: any) => {
      this.cancerSiteList = res['payLoad'];
    });
  }
  getReportTypeList() {
    this.dataAdminService.getAllReportTypes().subscribe((res: any) => {
      this.reportTypeList = res['payLoad'];
      console.log('Report Type List=>', res['payLoad']);
    });
  }
  assign(tab: any) {
    this.tab = tab;
  }

  changeReportStatus(event: any) {
    if (event.value == 'Yes') {
      this.reportService
        .changeReportStatus(this.reportId, 'REPORTED')
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          this.router.navigate(['/reports']);
        });
    }
  }

  changeReportAndOncoStatus(event: any) {
    if (event.value == 'Yes') {
      this.reportService
        .changeReportStatus(this.reportId, 'REPORTED')
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          this.router.navigate([
            '/first72_view_report/' + this.reportId + '/temp1',
          ]);
        });
      this.reportService
        .updateOncoBenchStatus(this.report.patient.ecrfId)
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          // this.router.navigate(['/first72_view_report/'+this.reportId+'/no_temp']);
        });
    }
  }

  addClinicalSummary() {
    this.isSubmitedClinicalSummary = true;
    if (
      this.clinicalSummary == null ||
      this.clinicalSummary == undefined ||
      this.clinicalSummary == ''
    ) {
      this.clinicalSummary = ' ';
    }
    this.reportService
      .addClinicalSummary(this.reportId, this.clinicalSummary)
      .subscribe((response: any) => {
        console.log(response);
        this.isSubmitedClinicalSummary = false;
        this.statusMsg = response['message'];
        this.showSuccess();
      });
    if (
      this.addNote == null ||
      this.addNote == undefined ||
      this.addNote == ''
    ) {
      this.addNote = '<p></p>';
    }
    if (this.isTargetFirst == false) {
      this.reportService
        .addAdditionalNotes(this.reportId, this.addNote)
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
        });
    }
    if (this.isTargetFirst == true) {
      if (
        this.disclaimerNote == null ||
        this.disclaimerNote == undefined ||
        this.disclaimerNote == ''
      ) {
        this.disclaimerNote = ' ';
      } else {
        console.log('add disclaimer', this.disclaimerNote?.length);
      }
      this.reportService
        .addDisclaimerNote(this.reportId, this.disclaimerNote)
        .subscribe((response: any) => {
          this.statusMsg = response['message'];
          this.showSuccess();
          console.log(response);
        });
    }

    if (this.isTargetFirst == true && this.only4bc) {
      if (
        this.keyInformation == null ||
        this.keyInformation == undefined ||
        this.keyInformation == ''
      ) {
        this.keyInformation = ' ';
      } else {
        console.log('add disclaimer', this.keyInformation?.length);
      }
      this.reportService
        .addKeyInformation(this.reportId, this.keyInformation)
        .subscribe((response: any) => {
          this.statusMsg = response['message'];
          this.showSuccess();
          console.log(response);
        });
    }

    if (this.isGermlineReport && this.interestGenes.length > 0) {
      this.reportService
        .addInterestGenes(this.reportId, this.interestGenes)
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
        });
    }
  }



  cancerSiteDeSelect(cancerSite: any) {
    this.reportService
      .addFirstCancerSite(this.reportId, 0)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerSiteSelect(cancerSite: any) {
    this.reportService
      .addFirstCancerSite(this.reportId, cancerSite.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerTypeSelect(cancerType: any) {
    console.log('select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, cancerType.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerTypeDeSelect(cancerType: any) {
    console.log('de-select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, 0)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  selectedInterpretations: string[] = [];
  originalPmids: string = '';
  addedPmids: any = '';
  selectedInterpretationPmids: string[] = [];
  selectedReferneceLink: string[] = [];
  addedRefernceLink: string = '';
  originalRefernce: string = '';

  onDisclaimerItemSelect(disclaimer: any) {
    console.log('DATA', disclaimer);

    let disclaimerData: any = this.disclaimerList.filter((element: any) => {
      return element.id == disclaimer.id;
    });
    this.selectedDisclaimer.push(disclaimerData[0].disclaimerDetails);
    if (this.isTargetFirst == true) {
      this.addNote = this.selectedDisclaimer.toString();
    } else {
      this.disclaimer = this.selectedDisclaimer.toString();
    }
    console.log('JJJJJ', this.disclaimer, 'kkk', this.selectedDisclaimer);

    setTimeout(() => {
      if (this.isGermlineReport) {
        this.textcount('editor1');
      } else {
        this.textcount('addNote-editors');
      }
    }, 500);
    setTimeout(() => {
      if (this.isGermlineReport) {
        this.textcount('editor1');
      } else {
        this.textcount('disclaimer-editors');
      }
    }, 500);
  }
  onDisclaimerItemSelects(disclaimer: any) {
    let disclaimerData: any = this.disclaimerList.filter((element: any) => {
      return element.id == disclaimer.id;
    });
    this.selectedDisclaimer.push(disclaimerData[0].disclaimerDetails);
    if (this.isTargetFirst == true) {
      this.addNote = this.selectedDisclaimer.toString();
    } else {
      this.disclaimer = this.selectedDisclaimer.toString();
    }
    console.log('JJJJJ', this.disclaimer, 'kkk', this.selectedDisclaimer);

    setTimeout(() => {
      if (this.isGermlineReport) {
        this.textcount('editor1');
      } else {
        this.textcount('addNote-editors');
      }
    }, 500);
    setTimeout(() => {
      if (this.isGermlineReport) {
        this.textcount('editor1');
      } else {
        this.textcount('disclaimer-editors');
      }
    }, 500);
  }

  addDisclaimer() {
    this.isSubmitedDisclaimer = true;
    if (this.disclaimer?.length == 0 || this.disclaimer == null) {
      this.disclaimer = '<p></p>';
    } else {
      console.log('add disclaimer', this.disclaimer?.length);
    }

    if (this.isTargetFirst || this.isTMH) {
      if (this.disclaimerNote && this.disclaimerNote?.length == 0) {
        this.disclaimerNote = ' ';
      } else {
        console.log('add disclaimer', this.disclaimerNote?.length);
      }
      this.reportService
        .addDisclaimerNote(this.reportId, this.disclaimerNote)
        .subscribe((response: any) => {
          this.statusMsg = response['message'];
          this.showSuccess();
          console.log(response);
        });
    }

    console.log(
      'add disclaimer',
      this.disclaimerNote?.length,
      this.disclaimerNote
    );
    if ( this.pmIds.length > 0 ) {
      this.reportService
        .addPMIds(this.reportId, this.pmIds)
        .subscribe((response: any) => {
          console.log(response);
          this.isSubmitedSummary = false;
        });
    }

    this.reportService
      .addDisclaimer(this.reportId, this.disclaimer)
      .subscribe((response: any) => {
        this.isSubmitedDisclaimer = false;
        this.statusMsg = response['message'];
        this.showSuccess();
        console.log(response);
      });
  }
  AddPMIDSAndAdditionalNotes() {
    if (
      this.addNote == null ||
      this.addNote == undefined ||
      this.addNote == ''
    ) {
      this.addNote = '<p></p>';
    }
    this.isSubmitedAdditionalNotes = true;
    this.reportService
      .addAdditionalNotes(this.reportId, this.addNote)
      .subscribe((response: any) => {
        console.log(response);
        this.isSubmitedAdditionalNotes = false;
        this.statusMsg = response['message'];
        this.showSuccess();
      });
    if (
      this.pmIds.length > 0 ) {
      this.reportService
        .addPMIds(this.reportId, this.pmIds)
        .subscribe((response: any) => {
          console.log(response);
          this.isSubmitedSummary = false;
        });
    }
  }
  textcount(classname: any) {
    var dom = document.getElementsByClassName(classname);
    var data: any = dom[0];
    this.editorText = data.outerText;
  }

  onDisclaimerItemDeSelect(disclaimer: any) {
    console.log('HHHHH', disclaimer);
    let disclaimerData: any = this.disclaimerList.filter((element: any) => {
      return element.id == disclaimer.id;
    });
    const index = this.selectedDisclaimer.indexOf(
      disclaimerData[0].disclaimerDetails
    );
    this.selectedDisclaimer.splice(index, 1);
    this.disclaimer = this.selectedDisclaimer.toString();
    setTimeout(() => {
      if (this.isGermlineReport) {
        this.textcount('editor1');
      } else {
        this.textcount('disclaimer-editors');
      }
    }, 1000);
  }

  addSampleID() {
    this.sampleIdData.ecrfId = this.report.patient.ecrfId;
    this.sampleIdData.samples = this.selected;
    this.sampleIdData.testPackage = this.report.testPackage;
    this.sampleIdData.sampleCollected = this.report.labDetails.sampleCollected;
    console.log(this.sampleIdData);

    this.reportService
      .addSampleID(this.sampleIdData)
      .subscribe((response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.getReport();
      });
  }

  addSelectedTrails(event: any, internalId: number) {
    this.isSelectedTrail = event['target']['checked'];
    this.trailService
      .addPDL1Trails(this.reportId, internalId, this.isSelectedTrail)
      .subscribe((response: any) => {
        console.log(response);
      });
  }
  reportDashbordExsists: boolean = false;

  changeReportType(event: any) {
    this.reportTypeChange = true;
    console.log('selected=>', event.name);
    this.reportType = event.name;
    this.reportService
      .addReportType(this.reportId, this.reportType)
      .subscribe((response: any) => {
        this.reportTypeChange = false;
        console.log('addReportType response', response);
        if (response['success']) {
          this.router.navigate(['/dash-reports', this.reportType.replace(/ /g, '_'), this.reportId]);
        }
      });
  }

  temp: any = 'temp1';
  tempSelection(event: any) {
    console.log('=>', event['target']['value']);
    this.temp = event['target']['value'];
  }
  isTemp1 = true;
  isTemp2 = false;
  isGermlineReport!: boolean;
  isTMH!: boolean;
  is4basecare: boolean = false;
  isHCG: boolean = false;
  referringClinician!: string;
  bracaInterpretation = false;
  ageOfBlock!: any;
  only4bc = false;

  getDisclaimerList() {
    if (this.isGermlineReport) {
      this.dataAdminService
        .disclaimerListByType('GERMLINE')
        .subscribe((res: any) => {
          this.disclaimerList = res['payLoad'];
        });
    } else {
      this.dataAdminService
        .disclaimerListByType('SOMATIC')
        .subscribe((res: any) => {
          this.disclaimerList = res['payLoad'];
        });
    }
  }
  getReport() {
    this.reportService.getReport(this.reportId).subscribe((response: any) => {
      this.report = response['payLoad'];
      console.log('clientType--->', this.report.clientType);
      console.log('report ==>', this.report);
      if (this.organisationName != null && this.organisationName != '') {
        this.getAndSetOncoBenchPatientsDetails(this.report?.patient?.ecrfId);
      }
      if (
        this.report.reportType == null &&
        this.organisationName != null &&
        this.organisationName.length > 0
      ) {
        this.reportService
          .addReportType(this.reportId, 'First 72 Solid')
          .subscribe((response: any) => {
            console.log('addReportType response', response);
            if (response['success']) {
              window.location.reload();
            }
          });
      }
      if (
        this.report.resultValue != null &&
        this.report.resultValue == 'Clinically significant alterations detected'
      ) {
        this.option1 = true;
        this.option2 = false;
      } else if (
        this.report.resultValue != null &&
        this.report.resultValue ==
          'No clinically significant alterations detected'
      ) {
        this.option1 = false;
        this.option2 = true;
      } else {
        this.option1 = false;
        this.option2 = false;
      }

      if (
        this.report.reportStatus == 'REPORTED' &&
        (this.organisationName == null || this.organisationName == '')
      ) {
        this.statusMsg = 'report is already delivered no operation can be done';
        this.showError();
        this.router.navigate(['/reports']);
        return;
      } else if (
        this.report.reportStatus == 'REPORTED' &&
        this.organisationName != null &&
        this.organisationName != ''
      ) {
        this.router.navigate([
          '/first72_view_report',
          this.reportId,
          'no_temp',
        ]);
      }

      if (this.report.patient.organisationName != null) {
        if (
          this.report.patient.organisationName.includes('4baseCare') ||
          this.report.patient.organisationName.includes('4basecare')
        ) {
          this.is4basecare = true;
        } else if (
          this.report.patient.organisationName.includes('HCG') ||
          this.report.patient.organisationName.includes('HCG')
        ) {
          this.isHCG = true;
          this.is4basecare = false;
          // this.temp="temp1"
        } else if (
          this.report.patient.organisationName.includes('Tata') ||
          this.report.patient.organisationName.includes('Homi Bhabha')
        ) {
          this.isTMH = true;
          this.is4basecare = false;
          // this.temp="temp1"
        } else {
          this.is4basecare = false;
          this.isTMH = false;
          // this.temp="temp1"
        }
      }
      if (this.report.reportType != null) {
        this.newreportType = this.report.reportType.name;
        this.titleService.setTitle(
          this.report.patient.ecrfId + ' - ' + this.report.reportType.name
        );

          this.isTargetFirst = true;
        this.getDisclaimerList();
      }

      if (this.report.firstCancerType != null) {
        this.selectedcancertype.push(this.report.firstCancerType);
      }

      this.significanceResult = this.report.significanceResult;
      this.clinicalSummary = this.report.clinicalSummary;
      this.interestGenes = this.report.interestGenes;
      this.addNote = this.report.addNotes;
      this.evidenceSummary = this.report.evidenceSummary;
      this.interpretation = this.report.interpretation;
      this.referringClinician =
        this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
      this.disclaimer = this.report.disclaimer;
      if (this.disclaimer == null || this.disclaimer == '<p></p>') {
        this.disclaimer = '';
      } else if (this.isTargetFirst == false) {
        this.selectedDisclaimer.push(this.disclaimer);
      }
      this.addNote = this.report.addNotes;

      if (this.addNote == null || this.addNote == '<div></div>') {
        this.addNote = '';
      } else if (this.isTargetFirst == true) {
        this.selectedDisclaimer.push(this.addNote);
      }
      this.disclaimerNote = this.report.disclaimerNote;
      this.keyInformation = this.report.keyInformation;
      if (this.interpretation == null || this.interpretation == '<p></p>') {
        this.interpretation = '';
      } else {
        this.selectedInterpretations.push(this.interpretation);
      }

      this.referenceWebsite = this.report.referenceWebsites;
      if (this.referenceWebsite != null || this.referenceWebsite != undefined) {
        this.originalRefernce = this.referenceWebsite;
      }
      this.pmIds = this.report.pmIds.toString();
      if (this.pmIds != null || this.pmIds != undefined) {
        this.originalPmids = this.pmIds;
      }

      if (this.report.labDetails != null) {
        this.dnaAndRnaDetailsReq.dnaAvailable =
          this.report.labDetails.dnaAvailable;
        if (this.report.labDetails.dnaAvailable) {
          this.dnaAvailableValue = 'yes';
        } else if (!this.report.labDetails.dnaAvailable) {
          this.dnaAvailableValue = 'no';
        }
        this.dnaAndRnaDetailsReq.dnaSequencingData =
          this.report.labDetails.dnaSequencingData;
        this.dnaAndRnaDetailsReq.rnaAvailable =
          this.report.labDetails.rnaAvailable;
        if (this.report.labDetails.rnaAvailable) {
          this.rnaAvailableValue = 'yes';
        } else if (!this.report.labDetails.rnaAvailable) {
          this.rnaAvailableValue = 'no';
        }
        this.dnaAndRnaDetailsReq.rnaSequencingData =
          this.report.labDetails.rnaSequencingData;
        if (
          this.report.labDetails.sampleSource != null ||
          this.report.labDetails.sampleSource != undefined
        ) {
          this.report.labDetails.sampleSource =
            this.report.labDetails.sampleSource.replace(/FFPE BlockId:/g, '');
        }
      }
    });
  }

  getCancerTypes() {
    this.dataAdminService.cancerTypeList().subscribe((response: any) => {
      this.cancerTypeList = response['payLoad'];
    });
  }
  getAndSetOncoBenchPatientsDetails(ecrfId: string) {
    this.reportService
      .getAndSetOncoBenchPatientsDetails(this.reportId)
      .subscribe((response: any) => {
        this.report = response['payLoad'];
        console.log('patient==>', this.report);
        this.clinicalSummary = this.report.clinicianIndication;
        this.report.labDetails.sampleId = this.report.labDetails.sampleId;
        this.report.labDetails.sampleSource =
          this.report.labDetails.sampleSource;
      });
  }

  option1: Boolean = false;
  option1Value: string = 'Clinically significant alterations detected';
  option2: Boolean = false;
  option2Value: string = 'No clinically significant alterations detected';

  onChange(status: any, value: string): void {
    let checked = status['target']['checked'];
    if (checked == true) {
      if (
        value == 'Clinically significant alterations detected' &&
        this.option2 == true
      ) {
        this.option2 = false;
        this.option1 = true;
      } else if (
        value == 'No clinically significant alterations detected' &&
        this.option1 == true
      ) {
        this.option1 = false;
        this.option2 = true;
      }
      this.reportService
        .addResultValue(this.reportId, value)
        .subscribe((response: any) => {});
    } else {
      this.option1 = false;
      this.option2 = false;
      this.reportService
        .addResultValue(this.reportId, 'NA')
        .subscribe((response: any) => {
          console.log(response);
        });
    }
  }

  addingRnaAndDna() {
    this.isSubmitedDnaRna = true;
    this.reportService.addingRnaAndDna(this.reportId, this.dnaAndRnaDetailsReq).subscribe(
      (response: any) => {
        console.log(response);
        this.isSubmitedDnaRna = false;
        this.statusMsg = response['message'];
        this.showSuccess();
      }
    );
  }
  dnaAvailable(event: any) {
    if (event.value == 'yes') {
      this.dnaAndRnaDetailsReq.dnaAvailable = true;
    } else {
      this.dnaAndRnaDetailsReq.dnaAvailable = false;
    }

    console.log(this.dnaAndRnaDetailsReq);
  }

  rnaAvailable(event: any) {
    if (event.value == 'yes') {
      this.dnaAndRnaDetailsReq.rnaAvailable = true;
    } else {
      this.dnaAndRnaDetailsReq.rnaAvailable = false;
    }
    console.log(this.dnaAndRnaDetailsReq);
  }
}
