import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Pdl1FileUploadService {
  apiUrl: any;
  private http: HttpClient
  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }
  uploadReport(file: File,filename:string): Observable<any> {
    this.apiUrl = "https://spark.oncoviz.com/api/3b7n6c4k8m2j1l0h5d9f8s7a6e9q4w3r2/files/upload_report";
    const formData = new FormData();
    formData.append('action', 'upload_report');
    formData.append('file', file);
    formData.append('filename', filename);
    return this.http.post(this.apiUrl, formData);
  }

  processReport(reportName: string,sys_prompt:string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const body = JSON.stringify({
      action: "process_report",
      report_name: reportName,
      sys_prompt:sys_prompt
    });
    this.apiUrl = "https://spark.oncoviz.com/api/3b7n6c4k8m2j1l0h5d9f8s7a6e9q4w3r2/files/process_report";
    return this.http.post(this.apiUrl, body, { headers });
  }

  getProcessedReport(reportName: string): Observable<any> {
    this.apiUrl = "https://spark.oncoviz.com/api/3b7n6c4k8m2j1l0h5d9f8s7a6e9q4w3r2/files/get_processed_report?report-name=";
    const fullUrl = `${this.apiUrl}${reportName}`;
    return this.http.get(fullUrl, { responseType: 'text' });
  }

  getProcessedReportData(id: any): Observable<any> {
    this.apiUrl = 'https://spark.oncoviz.com/api/3b7n6c4k8m2j1l0h5d9f8s7a6e9q4w3r2/files/get_processed_report/'+id;
    return this.http.get(this.apiUrl);
  }
}
